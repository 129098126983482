@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/RussoOne-Regular.ttf') format('truetype');
}

body {
  font-family: 'Russo One', 'Cambria Math';
}
